.workflow-section {
  position: relative;
  z-index: 1;
}

.workflow-section h2 {
  margin-bottom: 0.5rem !important;
}

.workflow-section__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  flex: 0.95;
}

.workflow-section__illustration img {
  display: block;
  width: 100%;
}

.workflow-section__container {
  max-width: 78rem;
  margin: 0 auto;
  background-color: $medium-grey;
  padding: 2rem;

  @media (min-width: 63rem) {
    padding: 4rem;
  }
}

.workflow-section__wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 2rem;
}

.workflow-section kat-link kat-icon {
  margin-left: 0.25rem;
}
