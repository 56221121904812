.points-section__container {
  background-color: $white;
  max-width: calc(1440px - 6rem);
  margin: 0 auto;
  padding: 2rem 1rem;

  @media (min-width: 46.875rem) {
    padding: 4rem;
    margin-top: -50px;
  }
}

.points-section__wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  gap: 3rem;
}

.points-section__wrapper > div {
  max-width: 40%;
}

.points-section img {
  opacity: 0.8;
}
