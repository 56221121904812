// Katal Component Overrides
.katal {
  background-color: transparent;
}

.katal {
  kat-button {
    button {
      padding: 0.45rem 1.5rem !important;
      margin-bottom: 5%;
      margin-left: 5%;
    }

    .tertiary {
      margin-bottom: 10px;
      margin-left: 0px;

      &:hover,
      &.active {
        background-color: #b6d0d3 !important;
        border-color: #b6d0d3 !important;
      }
    }
  }

  kat-progressbar {
    width: 100%;
  }

  kat-icon[size='small'][name='home'] {
    font-size: 18px;
    line-height: 18px;
    width: 18px;
    height: 18px;
  }

  kat-dropdown-button {
    button {
      line-height: 16px;
    }

    &[variant='secondary'] button {
      background-color: #fff;
      border: 1px solid #7fc2bb;
      color: #002f36;
    }
  }
}

.katal kat-dropdown-button[variant='secondary'] {
  --kat-dropdown-button-background: #fff;
  --kat-dropdown-button-background-hover: #7fc2bb;
  --kat-dropdown-button-border-color: #7fc2bb;
  --option-border-style: max(35px);
}

kat-spinner {
  position: relative;
  z-index: 999;
  height: 2em;
  width: 2em;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.site-navigation__list-item kat-icon {
  position: relative;
  top: -1px;
  padding-right: 5px;
}

.site-navigation__list-item a kat-icon {
  color: #002f36;
}

// overrides .katal a
.site-navigation__list-item a:active,
.site-navigation__list-item a:hover {
  text-decoration: none !important;
}

// overrides default react-flow styles
.react-flow__pane {
  cursor: grab;
}

.react-flow__node-default {
  border: none !important; // overrides default react-flow styles
  box-shadow: $shadow;
  width: fit-content !important;
  min-width: 22rem !important;
  height: 11.75rem;
}

.react-flow__handle {
  visibility: hidden;
}

.react-flow__edge-path {
  stroke-width: 2 !important;
  stroke: $dark-grey !important;
}

.react-flow__controls {
  margin-bottom: 1.25rem !important;
}

kat-link:hover {
  cursor: pointer;
}

.data-table__cell p {
  margin: 0 !important;
}
