.site-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  background: $dark-green;
  padding: 0.5rem 1rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 8249;
}

.site-header__heading-container {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 0;
}

.site-header__menu-button {
  display: block;
  background: none;
  color: $white;
  border: none;
  padding: 0 1rem;
  margin-right: 0.5rem;

  &:hover {
    background-color: $dark-blue;
  }

  @media screen and (min-width: $side-panel-bp) {
    display: none;
  }
}

.site-header__logo-container {
  display: flex;
  gap: 0.35rem;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
}

.site-header__logo-container a {
  line-height: 1;
}

.site-header__logo {
  display: block;
  height: auto;
  width: 7.5rem;
}

.site-header h1 {
  margin-bottom: 0; // overrides .katal h1
}

.site-header__content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1.5rem;
}

.site-header__nav ul {
  margin: 0;
  padding: 0;
  display: none;
  gap: 1rem;

  @media screen and (min-width: $side-panel-bp) {
    display: flex;
  }
}

.site-header__nav kat-link {
  color: $white;
}

.site-header__account {
  display: none;
  min-width: 15rem;
  max-width: 30rem;
  margin-right: 1rem;

  @media screen and (min-width: $side-panel-bp) {
    display: block;
  }
}

.site-header__username {
  color: $white;
  margin-right: 0.5rem;
}

.site-header__user-photo {
  object-fit: cover;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
}

.site-header__slide-out-panel {
  box-shadow: $shadow;
  background-color: $white;
  position: fixed;
  width: 100%;
  max-width: $mobile-menu-width;
  height: 100%;
  padding: 2rem;
  z-index: 9999;
  display: none;
  top: 3.5rem;
  left: 0;
}

.slide-out-panel__nav kat-link {
  font-size: 1rem;
}

.slide-out-panel__nav ul {
  list-style-type: none;
  padding: 0;
  margin: 1rem 0;
}

.site-header__slide-out-panel--open {
  display: block;
}

.site-header__overlay {
  display: none;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 9998;
}

.site-header__overlay--visible {
  display: block;
}
