.community-section__container {
  margin: 4rem auto;
  max-width: 90rem;
  padding: 3rem 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 4rem;

  @media (min-width: 60rem) {
    padding: 0 3rem;
  }
}

.community-section__heading {
  flex: 2;
}

.community-section__content {
  max-width: 25rem;
}

.community-section__list {
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
}

.community-section__wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: space-around;
}

.community-section kat-link kat-icon {
  margin-left: 0.25rem;
}

.community-section kat-link img {
  display: inline-block;
  margin-right: 1rem;
}

.community-section__slack {
  margin-bottom: 1rem;
}
