.log-window {
  margin-top: 0.5rem !important;
  min-height: 500px;
}

.log-control-buttons {
  display: flex;
  gap: 0.25rem;
  width: 100%;
  position: sticky;
  padding: 2rem;
  top: 4rem;
}

.log-view {
  display: flex;
  gap: 1.5rem;
  padding: 1rem 0;
  width: 100%;
}

.log-view__time,
.log-view__level {
  width: 10%;
}

.log-view__log {
  width: 80%;
}
