.subnav-menu__menu-text {
  font-weight: bold;
  color: $dark-blue;
}

.subnav-menu__display {
  cursor: pointer;
  display: flex;
  align-items: center;
  background: none;
  gap: 0.5rem;
  border: none;
  padding: 1rem 0 1rem 0;
}

.subnav-menu__display:hover kat-icon {
  color: $dark-blue;
}
