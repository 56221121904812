.date-range-picker {
  padding-bottom: 1rem;
}

.date-range-picker__wrapper {
  display: flex;
  align-items: flex-end;
  gap: 2rem;
}

.date-range-picker-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.date-range-picker__range-shortcuts {
  display: flex;
  gap: 0.5rem;
}
