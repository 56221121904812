.no-gutter {
  padding: 0;
  padding-bottom: 3.125rem;
}

.icon-button {
  background: none;
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0.5rem;
}

.hidden {
  display: none !important;
}

.content-loading {
  background-color: $light-grey !important;
  animation: section-loading 0.75s linear infinite alternate;
}

.link-group {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
}

.model-artifact-table {
  min-height: 20rem;
}

.min-h-10 {
  min-height: 10rem;
}

.w-full {
  width: 100%;
}
