.content-layout {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  grid-template-rows: auto 1fr auto;
  margin: 0 auto;
  max-width: $site-layout-width;
}

.content-layout__heading-container-background {
  background-color: #fff;
}

.content-layout__heading-container {
  padding: 1rem;
}

.content-layout__description {
  max-width: 37.5rem;
}

.content-layout section {
  margin-bottom: 2rem;
}

.content-layout__heading-container h1 {
  margin-bottom: 1rem;
}

.content-layout__content {
  padding: 3rem 1rem;
}
