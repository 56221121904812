.site-container__side-panel {
  display: none;

  @media screen and (min-width: $side-panel-bp) {
    display: block;
  }
}

.site-container__notice {
  margin-bottom: 1rem;
  padding: 0 1rem;
}

.site-container__main {
  margin-top: 3.5rem;
  grid-column: 1 / 13;
}

.site-container__footer {
  grid-column: 1 / 13;
}
