.hero-section {
  min-height: 35.68rem;
  background-size: cover;
  color: $white;
  display: flex !important;
  align-items: center;
  margin-top: -1px;
}

.hero-section__container {
  margin: 0 auto;
  max-width: 90rem;
  padding: 3rem 1rem;

  @media (min-width: 60rem) {
    padding: 0 3rem;
  }
}

.hero-section__wrapper {
  @media (min-width: 60rem) {
    max-width: 65%;
  }
}

.hero-section kat-link {
  --kat-link-color: $white;
  --kat-link-color-hover: $white;
}

.hero-section kat-link kat-icon {
  margin-left: 0.25rem;
}
