.homepage-footer {
  background-color: $medium-grey;
  padding: 1rem 0;
}

.homepage-footer ul {
  list-style-type: none;
  display: flex;
  justify-content: center;
  gap: 1rem;
  padding: 0;
  margin: 0;
}

.homepage-footer kat-link kat-icon {
  margin-left: 0.25rem;
}
