// Overall Layout
$site-layout-width: 90rem;
$side-panel-bp: 86.875rem;

// Breakpoints
$toast-message-portal-bp: 700px;

// Color
$white: #ffffff;
$light-grey: #fafafa;
$medium-grey: #e9eff0;
$dark-grey: #bbbbbb;
$black: #000000;
$light-blue: #ebf1f2;
$dark-blue: #008296;
$light-green: #e3eced;
$dark-green: #002e36;

$green: #008800;
$red: #c21f39;
$blue: #0074d9;
$yellow: #f9cd0b;

// Effects
$shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);

// Mobile Menu
$mobile-menu-width: 25rem;
