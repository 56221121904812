.development-section {
  background-color: $dark-blue;
  color: $white;
  padding-top: 150px;
  padding-bottom: 3rem;
  margin-top: -100px;
}

.development-section__container {
  margin: 0 auto;
  max-width: 90rem;
  padding: 3rem 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 4rem;

  @media (min-width: 60rem) {
    padding: 0 3rem;
  }
}

.development-section__heading {
  flex: 2;
}

.development-section__content {
  max-width: 25rem;
}

.development-section__list {
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;
}

.development-section__wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: space-around;
}

.development-section kat-link {
  --kat-link-color: $white;
  --kat-link-color-hover: $white;
  text-decoration: underline;
  font-weight: bold;
}

.development-section kat-link kat-icon {
  margin-left: 0.25rem;
}
