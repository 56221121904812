.error-text,
.helper-text {
  font-size: 0.75rem;
  line-height: 1.5rem;
  margin: 0.5rem 0 !important; // overrides .katal p
}

.error-text {
  color: $red;
}

.helper-text {
  opacity: 0.85;
}

.italics {
  font-style: italic;
}

.sub-heading {
  font-size: var(--kat-heading-400-font-size);
  line-height: var(--kat-heading-500-line-height);
  font-style: normal;
}
